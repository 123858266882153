import { combineReducers } from 'redux';
//import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';
import settings from './settings';
//import createHistory from 'history/createHashHistory';

import { createHashHistory  } from "history";



export const history = createHashHistory ();

const reducers = {
  router: connectRouter(history),
  settings
};

export default combineReducers(reducers);
